$(function()
{
    /**
     * Place the CSRF token as a header on all pages for access in AJAX requests
     */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.ui.dropdown').dropdown();
    $('.ui.menu .ui.dropdown').dropdown({on: 'hover'});
    $('.ui.checkbox').checkbox();
    $('.ui.accordion').accordion();
    $('.popup').popup();
});